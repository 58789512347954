import { Folder } from "./Document";

export function ListView({ data }) {
  return (
    <div className="flex flex-col gap-[5px] md:gap-[15px] overflow-auto max-h-[calc(100vh-250px)] md:max-h-[calc(100vh-210px)] lg:max-h-[calc(100vh-150px)]">
      {data.map((item) => (
        <Folder type="list" key={item.id} data={item} />
      ))}
    </div>
  );
}
