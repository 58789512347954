import { useState } from "react";
import { PopupModal } from "../../components/PopupModal";
import { Button } from "../../components/Button";
import { zipCodes } from "../../assets/data/zip_codes";
import ZipCodes from "zipcodes";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/location-target.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { Loading } from "../../components/Loading/Loading";

export function PromptZipModal({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void;
  onConfirm: (zip: string) => void;

}) {
  const [zip, setZip] = useState("");
  const [locationLoading, setLocationLoading] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const confirmZip = async () => {
    onConfirm(zip);
  };

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code.trim(), 10)) && !!zipCodes[code.trim()])
  const allNumsRegex = /^[0-9\b]+$/;
  const handleZipChange = (e) => {
    const value = e.target.value.trim()
    if ((!allNumsRegex.test(value) && value !== "") || value.length > 5) {
      return;
    }
    setZip(value);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter" && isZipValid(zip)) {
      confirmZip();
    }
  };

  const useCurrentLocation = () => {
    function errorCallback(error) {
      if (error.code === error.PERMISSION_DENIED) {
        setLocationLoading(false)
        setBlocked(true)
      }
    }
    if (navigator.geolocation) {
      setLocationLoading(true)
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocationLoading(false)
        var lat = position.coords.latitude;
        var long = position.coords.longitude;
        const matched = ZipCodes.lookupByCoords(lat, long);
        if (matched && matched.zip) {
          setZip(matched.zip);
          onConfirm(matched.zip);
        }
        setBlocked(false)
      }, errorCallback);
    }
  }

  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white 
      rounded-2xl flex-col justify-start items-center gap-6 flex w-full w-[90%] md:mx-auto md:w-[700px] max-w-[700px]">
      <div
        className="absolute w-[calc(98%-5px)] max-w-[678px] md:w-[678px] h-[140px] md:h-[115px] mt-[-22px] rounded-lg z-[-1] opacity-80"
        style={{
          backgroundImage: "linear-gradient(180deg, rgb(196, 243, 229) 0%, rgba(153, 153, 153, 0))",
          backgroundSize: "contain",
          backgroundRepeat: "repeat",

        }}
      />
      <div
        className="absolute w-[calc(98%-5px)] max-w-[678px] md:w-[678px] h-[140px] md:h-[115px] mt-[-22px] rounded-lg z-[-1] opacity-100"
        style={{
          backgroundImage: "url('/images/header-bg-overlay.png')",
          backgroundSize: "contain",
          backgroundRepeat: "space",

        }}
      />
      <div className="flex flex-col w-full">
        <div className="justify-end items-center gap-3 flex">
          <button onClick={onCancel} style={{ transform: "translate(15px,-15px)" }}>
            <CloseIcon className="w-5 h-5 opacity-80" />
          </button>
        </div>
        <p className="text-center text-4xl text-black font-bold mb-4">
          Find providers <span className="text-primary">near you</span>
        </p>
        <p className="text-center text-l text-black/80 font-medium">
          Enter your zip code to connect with a BCBA that best matches your family's needs
        </p>

        <form
          onKeyUp={onKeyPress}
          className="flex flex-col relative w-[300px] mx-auto"
        >
          <input
            id="zipInput"
            placeholder="Enter your zip code"
            value={zip}
            onChange={handleZipChange}
            type="text"
            className="justify-center flex-1 !outline-none !bg-transparent p-4 text-left mt-4 !rounded-md w-full border border-[#D9D9E7]"
          />
          <button
            onClick={confirmZip}
            className="p-2 absolute right-[0px] top-[9.5px]"
            disabled={!isZipValid(zip)}
          >
            <ArrowRight className={`w-[55px] h-[55px] relative ${isZipValid(zip) ? '' : 'opacity-25'}`} />
          </button>

          <div className="mb-5"></div>

          {navigator.geolocation ?
            <p className="text-center  mt-1">
              <span onClick={useCurrentLocation}
                className={`${!locationLoading ? 'cursor-pointer text-primary' : 'text-gray-400'} inline-block`}>
                {locationLoading ? <Loading className="mb-[2px] inline-block" /> : <LocationIcon className="ml-1 mb-[2px] w-6 h-6 inline-block" />}
                <span className="ml-2 font-medium">{locationLoading ? 'Finding Your Location' : `Use My Location`}</span>
              </span>
              {blocked && <div className="text-sm text-gray-500">(Location service blocked - please enable)</div>}
            </p>
            : null}

          {/* <div className="flex gap-3 items-center w-full mt-6">
            <Button
              onClick={confirmZip}
              className="!rounded-full w-full flex-1 bg-primary text-white"
              variant="primary"
              disabled={!isZipValid(zip)}
            >
              Confirm Zip Code
            </Button>

          </div> */}
          <div className="mb-3"></div>
        </form>
      </div>
    </PopupModal >
  );
}
