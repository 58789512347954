import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";

function Step({
  title,
  active,
  completed,
  number,
}: {
  title: string;
  active?: boolean;
  completed?: boolean;
  number: number;
}) {
  return (
    <div className="relative flex flex-col items-center">
      <div
        className={`w-9 h-9 rounded-full border-[0.5px] flex items-center justify-center border-primary ${active || completed ? "bg-primary text-white" : "bg-[#E8F3F2]"
          }`}
      >
        {completed ? (
          <CheckIcon className="[&_path]:stroke-white" />
        ) : (
          <span>{number}</span>
        )}
      </div>
      <span
        className={`absolute -bottom-12 text-center text-xs sm:text-sm lg:text-base md:-bottom-8 md:whitespace-nowrap ${active ? "font-bold" : ""
          }`}
      >
        {title}
      </span>
    </div>
  );
}

export function ProgressStep({
  currentStep,
  isGoogleId,
}: {
  currentStep: number;
  isGoogleId: boolean;
}) {
  return (
    <div className="flex justify-center mb-2">
      <div className="flex-1 bg-white rounded-[16px] px-16 md:px-20 lg:px-8 py-5 max-w-[820px] lg:w-[43vw] pb-4  md:pb-4">
        <div className="flex items-center justify-center">
          <Step
            title={isGoogleId ? "Client Information" : "Personal Information"}
            completed={currentStep > 1}
            active={currentStep === 1}
            number={1}
          />
          <div
            className={`flex-1 lg:max-w-[200px] border-b-[2px] ${currentStep > 1
              ? "border-primary"
              : "border-dashed border-black/50"
              }`}
          />

          <Step
            title="Insurance Details"
            completed={currentStep > 2}
            active={currentStep === 2}
            number={2}
          />

          <div
            className={`flex-1 lg:max-w-[200px] border-b-[2px] ${currentStep > 2
              ? "border-primary"
              : "border-dashed border-black/50"
              }`}
          />

          <Step
            title="Diagnostic Information"
            completed={currentStep > 3}
            active={currentStep === 3}
            number={3}
          />
        </div>
        <div className="bg-[#E8F3F2] w-fit px-5 mx-auto rounded-[40px] border  text-[#212424] text-center text-xs sm:text-base font-medium mt-16 md:mt-12 mb-0">
          Questions? Call
          <a href="tel:1234567890"
            className="text-primary text-xs sm:text-base font-bold leading-snug ml-1"
          >
            (512) 766-2637
          </a>
        </div>
      </div>
    </div>
  );
}
