import { useState } from "react";
import { ToolsView } from "./components/ToolsView";
import { ImageView } from "../../components/Image";

function ToolItem({ title, description, image, onClick }) {
  return (
    <div
      role="button"
      onClick={onClick}
      className="p-4 md:p-7 bg-white rounded-xl justify-start items-center gap-7 flex hover:shadow-card"
    >
      <ImageView
        className="w-20 h-20 rounded-full object-cover"
        alt="tool"
        src={image}
        loading="lazy"
      />
      <div className="flex-col justify-start items-start gap-2 inline-flex">
        <div className="text-black text-xl md:text-2xl font-semibold">{title}</div>
        <div className="self-stretch text-zinc-600 text-sm md:text-base font-normal">
          {description}
        </div>
      </div>
    </div>
  );
}

interface ToolType {
  url: string;
  title: string;
  image: string;
  isExternal?: boolean;
}

const tools: {
  [key: string]: ToolType;
} = {
  aloha: {
    url: "https://identity.alohaaba.com/user/login",
    title: "Aloha ABA",
    image: "/images/aloha.png",
    isExternal: true,
  },
  hirasmus: {
    url: "https://app.hirasmus.com/",
    title: "Hi Rasmus",
    image: "/images/hirasmus.png",
    isExternal: true,
  },
  rippling: {
    url: "https://app.rippling.com/login",
    title: "Rippling",
    image: "/images/rippling.png",
    isExternal: true,
  },
  mercury: {
    url: "https://app.mercury.com/login",
    title: "Mercury",
    image: "/images/mercury.png",
    isExternal: true,
  },
  quickbooks: {
    url: "https://app.qbo.intuit.com/app/login?loadCustomerAssistanceAssets=us&product=qbOnlineAccountant",
    title: "Quickbooks",
    image: "/images/quickbooks.png",
    isExternal: true,
  },
  flychain: {
    url: "https://application.flychain.us/",
    title: "Flychain",
    image: "/images/flychain.png",
    isExternal: true,
  },

  tcb: {
    url: "https://my.dashboardmd.com/Login.aspx?ReturnUrl=%2f",
    title: "TCB Billing",
    image: "/images/tcb-billing.png",
    isExternal: true,
  },
};

export function Tools() {
  const [selectedTool, setSelectedTool] = useState<ToolType | null>(null);

  const handleToolClick = (tool: string) => {
    const toolInfo = tools[tool];
    if (toolInfo.isExternal) {
      window.open(toolInfo.url, "_blank");
      return;
    }
    setSelectedTool(tools[tool]);
  };

  const handleToolClose = () => {
    setSelectedTool(null);
  };
  return (
    <div>
      <div>
        <span className="text-black text-xl md:text-3xl font-bold ">My Tools</span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-8 pb-12">
        <ToolItem
          image="/images/aloha.png"
          title={"Aloha ABA"}
          description="Core practice management software."
          onClick={() => handleToolClick("aloha")}
        />
        <ToolItem
          title="Hi Rasmus"
          description="Data collection."
          image="/images/hirasmus.png"
          onClick={() => handleToolClick("hirasmus")}
        />
        <ToolItem
          title="Rippling"
          description="HR, benefits, and payroll."
          image="/images/rippling.png"
          onClick={() => handleToolClick("rippling")}
        />
        <ToolItem
          title="Mercury"
          description="Banking."
          image={"/images/mercury.png"}
          onClick={() => handleToolClick("mercury")}
        />
        <ToolItem
          title={"Flychain"}
          description={"Finance, accounting, and bookkeeping."}
          image="/images/flychain.png"
          onClick={() => handleToolClick("flychain")}
        />
        <ToolItem
          title={"TCB Billing"}
          description={"Billing."}
          image={"/images/tcb-billing.png"}
          onClick={() => handleToolClick("tcb")}
        />
      </div>
      {!!selectedTool && (
        <ToolsView
          isOpen={!!selectedTool}
          onClose={handleToolClose}
          tool={selectedTool}
        />
      )}
    </div>
  );
}
