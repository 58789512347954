import { useEffect, useState } from "react";
import { zipCodes } from "../../../assets/data/zip_codes";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { Button } from "../../../components/Button";

export function SearchView({
  search,
  searchByZip,
  setSearchByZip,
  onFilter,
  onSearch,
  hasFilters,
}: {
  search: string;
  searchByZip: boolean;
  setSearchByZip: (value: boolean) => void;
  onFilter: () => void;
  hasFilters: boolean;
  onSearch: (search: string) => void;
}) {
  const [zipValid, setZipValid] = useState(false);
  const [searchText, setSearchText] = useState(search);
  const allNumsRegex = /^[0-9\b]+$/;
  const handleSearchChange = (e) => {
    if (searchByZip) {
      if ((!allNumsRegex.test(e.target.value) && e.target.value !== "") || e.target.value.trim().length > 5) {
        return;
      }
      const value = e.target.value.trim()
      const valid = isZipValid(value) === true;
      setZipValid(valid);
      if (valid || value === "") {
        onSearch(e.target.value);
      }
      setSearchText(value);
    } else {
      setSearchText(e.target.value);
      onSearch(e.target.value);
    }
  };

  useEffect(() => {
    setSearchText(search)
  }, [search]);

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code.trim(), 10)) && !!zipCodes[code.trim()])

  return (
    <>
      <div className="h-[90px] absolute -bottom-[52px] md:-bottom-11 w-full left-0 flex justify-center">
        <div className={`max-w-[347px] md:max-w-[660px] mx-4 md:mx-0 relative flex`}>
          <svg
            width="357"
            height="72"
            viewBox="0 0 357 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="md:hidden"
          >
            <mask
              id="path-1-outside-1_915_108521"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="357"
              height="72"
              fill="black"
            >
              <rect fill="white" width="357" height="72" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 36C5 18.8792 18.8792 5 36 5H247C260.014 5 271.154 13.0188 275.751 24.385C277.442 28.5687 281.123 32 285.636 32V32C290.068 32 293.703 28.6787 295.489 24.6227C300.058 14.2451 310.433 7 322.5 7C338.792 7 352 20.2076 352 36.5C352 52.7924 338.792 66 322.5 66C310.099 66 299.486 58.3484 295.123 47.5089C293.468 43.3973 289.866 40 285.434 40V40C281.037 40 277.448 43.3402 275.826 47.4275C271.277 58.8928 260.085 67 247 67H36C18.8792 67 5 53.1208 5 36Z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 36C5 18.8792 18.8792 5 36 5H247C260.014 5 271.154 13.0188 275.751 24.385C277.442 28.5687 281.123 32 285.636 32V32C290.068 32 293.703 28.6787 295.489 24.6227C300.058 14.2451 310.433 7 322.5 7C338.792 7 352 20.2076 352 36.5C352 52.7924 338.792 66 322.5 66C310.099 66 299.486 58.3484 295.123 47.5089C293.468 43.3973 289.866 40 285.434 40V40C281.037 40 277.448 43.3402 275.826 47.4275C271.277 58.8928 260.085 67 247 67H36C18.8792 67 5 53.1208 5 36Z"
              fill="white"
            />
            <path
              d="M275.826 47.4275L280.473 49.2715L275.826 47.4275ZM295.489 24.6227L300.065 26.6377L295.489 24.6227ZM295.123 47.5089L290.484 49.3758L295.123 47.5089ZM275.751 24.385L271.115 26.2594L275.751 24.385ZM36 0C16.1177 0 0 16.1177 0 36H10C10 21.6406 21.6406 10 36 10V0ZM247 0H36V10H247V0ZM280.386 22.5105C275.052 9.32033 262.12 0 247 0V10C257.907 10 267.257 16.7173 271.115 26.2594L280.386 22.5105ZM322.5 2C308.38 2 296.252 10.4818 290.913 22.6076L300.065 26.6377C303.864 18.0085 312.486 12 322.5 12V2ZM357 36.5C357 17.4462 341.554 2 322.5 2V12C336.031 12 347 22.969 347 36.5H357ZM322.5 71C341.554 71 357 55.5538 357 36.5H347C347 50.031 336.031 61 322.5 61V71ZM290.484 49.3758C295.583 62.0434 307.99 71 322.5 71V61C312.209 61 303.388 54.6534 299.761 45.642L290.484 49.3758ZM247 72C262.203 72 275.194 62.5772 280.473 49.2715L271.178 45.5835C267.359 55.2084 257.967 62 247 62V72ZM36 72H247V62H36V72ZM0 36C0 55.8823 16.1177 72 36 72V62C21.6406 62 10 50.3594 10 36H0ZM285.434 35C278.326 35 273.278 40.2904 271.178 45.5835L280.473 49.2715C281.617 46.39 283.747 45 285.434 45V35ZM285.636 37C292.734 37 297.797 31.7861 300.065 26.6377L290.913 22.6076C289.608 25.5712 287.402 27 285.636 27V37ZM299.761 45.642C297.643 40.3792 292.603 35 285.434 35V45C287.129 45 289.293 46.4154 290.484 49.3758L299.761 45.642ZM271.115 26.2594C273.282 31.6165 278.411 37 285.636 37V27C283.836 27 281.603 25.5208 280.386 22.5105L271.115 26.2594Z"
              fill="#C4F3E5"
              mask="url(#path-1-outside-1_915_108521)"
            />
          </svg>
          <img
            src="/images/search.png"
            alt="search"
            className="hidden md:block w-auto h-auto"
            loading="lazy"
          />
          <div className="flex-1 z-[1] absolute left-4 w-[72%] md:w-[80%] flex items-center top-[13px] md:top-3">
            <input
              id="searchInput"
              placeholder={searchByZip ? "Search by Zip Code" : "Search by Provider Name"}
              value={searchText}
              onChange={handleSearchChange}
              type="text"
              className="flex-1 !outline-none mx-1 md:mx-4 !bg-transparent"
              style={{ ...(isSafari ? { width: "60px" } : { width: "inherit" }) }}
            />
            <div className={`min-w-12 min-h-12 w-12 h-12 hover:shadow-lg md:min-w-16 md:min-h-16 md:w-16 md:h-16 flex items-center justify-center rounded-full ${(!searchByZip || zipValid) ? 'bg-primary' : 'bg-[gray]'}`}>
              <SearchIcon />
            </div>
          </div>
          <div className="z-[1] flex w-16 md:w-[88px] absolute right-[2px] top-[13px] md:top-3 lg:top-[13.5px] justify-center items-center">
            <button
              onClick={onFilter}
              className="w-12 h-12 relative hover:shadow md:w-16 md:h-16 flex items-center justify-center rounded-full border border-black/20"
            >
              <FilterIcon />
              {hasFilters && (
                <div className="absolute bg-primary p-[5px] md:p-[7px] top-1 right-[1px] rounded-full" />
              )}
            </button>
          </div>

        </div>

        <div className="w-52 hidden absolute md:flex text-primary cursor-pointer justify-center items-center whitespace-nowrap mt-1 h-[20px] mx-auto" style={{ 'transform': `translateY(95px)` }} onClick={() => {
          setSearchByZip(!searchByZip)
          setSearchText('')
        }}>
          <Button
            variant="primary"
            className="px-4 h-6 !rounded-full bg-primary"
          >
            Search by {searchByZip ? "Provider Name" : "Zip Code"}
          </Button>

        </div>

        <div className="md:hidden absolute bottom-[-15px] justify-center items-center text-primary cursor-pointer mx-auto"
          onClick={() => {
            setSearchByZip(!searchByZip)
            setSearchText('')
          }}>
          <Button
            variant="primary"
            className="h-6 !rounded-full bg-primary"
          >
            Search by {searchByZip ? "Provider Name" : "Zip Code"}
          </Button>
        </div>
      </div>


    </>
  );
}
