import React from "react";
import { ReactComponent as Email } from "../../../../assets/icons/email.svg";
import OtpInput from "react-otp-input";
import { ReactComponent as AlertIcon } from "../../../../assets/icons/Alert.svg";
import { api } from "../../../../services/api";
import { Button } from "../../../../components/Button";
import { getUser, setUser } from "../../../../services/utils";

export function ConfirmEmail({
  onSuccess,
  onClose,
  userId,
  email,
}: {
  onSuccess?: () => void;
  onClose?: () => void;
  userId: string;
  email: string;
}) {
  const [loading, setLoading] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await api.verifyEmail(userId, {
        otp,
      });
      if ([201, 200].includes(response.status)) {
        let user = getUser();
        user.isEmailVerified = true;
        setUser({ ...user });
        onSuccess && onSuccess();
      }
      setLoading(false);
    } catch (e: any) {
      if (e.response.data.message) {
        setError(e.response.data.message);
      } else {
        setError("Something went wrong");
      }
      setLoading(false);
    }
  };

  const sendVerificationCode = async () => {
    if (!userId) return;
    const response = await api.initEmailVerification(userId);
  };

  return (
    <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_100px)] overflow-auto">
      <div className="relative rounded-lg p-6 bg-white flex flex-col items-center mx-3">
        <div className="p-6 rounded-full w-fit border border-primary flex">
          <Email className="w-12 h-12" />
        </div>
        <div className="text-dark1 text-xl sm:text-2xl font-semibold leading-loose">
          Confirm your email
        </div>
        <p className="text-center text-dark2 text-xs sm:text-sm font-normal leading-normal ">
          Please enter the verification code sent to your email{" "}
          <span className="text-black">'{email}'</span> to verify your email
          address.
        </p>
        <div className="my-6 flex flex-col gap-5">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={() => <div className="w-4 h-px"></div>}
            shouldAutoFocus={true}
            inputType="number"
            renderInput={(props) => (
              <input
                {...props}
                className="remove-arrow !w-10 h-12 px-3.5 py-3.5 bg-white rounded border border-gray-200 items-center flex text-sm"
              />
            )}
          />
        </div>
        <Button
          disabled={otp.length < 4}
          loading={loading}
          className="w-full"
          variant="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        {error && (
          <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
            <AlertIcon /> {error}
          </div>
        )}
        <div className="my-6 h-px border-b w-full border-gray-200"></div>
        <div className="flex text-sm text-dark2 gap-1.5 mt-4 justify-center">
          Didn't receive code?
          <button
            type="button"
            className="text-black border-b pb-1 border-dashed border-primary font-medium"
            onClick={sendVerificationCode}
          >
            Resend
          </button>
        </div>
      </div>
    </div>
  );
}
