import { ReactComponent as ChevronLeft } from "../../../assets/icons/chevron-left.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { Button } from "../../../components/Button";
import { useEffect, useMemo, useRef, useState } from "react";
import { TextInput } from "../../../components/TextInput";
import { TextArea } from "../../../components/Textarea";
import { ChipInput } from "../../../components/ChipInput";
import { ShowToast } from "../../../services/toast";
import { useUser } from "../../auth/context/user-context";
import { fileUpload } from "../../../services/file-upload";
import { api } from "../../../services/api";
import {
  getInitial,
  getLocationListForSelect,
  setUser,
} from "../../../services/utils";
import { FooterAction } from "./FooterAction";
import { SelectInput } from "../../../components/SelectInput";
import { ImageView } from "../../../components/Image";
import { ImageCrop } from "../../../components/ImageCropper/ImageCrop";
import { acceptedInsurances, focusAreas } from "../../../constant";
import { MultiSelect } from "../../../components/MultiSelect";
import { zipCodes } from "../../../assets/data/zip_codes";

export function EditProfile({
  provider,
  onUpdate,
  onBack,
  onLogout,
}: {
  provider: any;
  onUpdate: () => void;
  onBack: (e: any) => void;
  onLogout: any;
}) {
  const { setUserInfo } = useUser() as any;
  const profileImgRef = useRef<HTMLInputElement | null>(null);
  const [profileImg, setProfileImg] = useState<any>(null);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<any>({});
  const [showCropModal, setShowCropModal] = useState(false);

  const handleCrop = () => {
    setShowCropModal(true);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setForm((prev: typeof form) => ({ ...prev, [name]: value }));
  };

  const handleFocusChange = (e: any, index: number) => {
    const { value } = e.target;
    setForm((prev: typeof form) => {
      const focusAreas = prev.focusAreas.map((f: string, i: number) =>
        i === index ? value : f
      );
      return { ...prev, focusAreas };
    });
  };

  const handleAddFocus = () => {
    setForm((prev: typeof form) => ({
      ...prev,
      focusAreas: [...prev.focusAreas, ""],
    }));
  };

  const handleRemove = (index: number) => {
    setForm((prev: typeof form) => ({
      ...prev,
      focusAreas: prev.focusAreas.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload: typeof form = {
      email: form.email,
      userType: form.userType,
      firstName: form.firstName,
      lastName: form.lastName,
      addressState: form.addressState,
      addressZip: form.addressZip,
      profession:
        form?.profession?.split(",")?.map((p: string) => p.trim()) || [],
      focusAreas: form.focusAreas,
      practiceAreas: form.practiceAreas,
      acceptedInsurances: form.acceptedInsurances,
      isAvailable: form.isAvailable,
      profileSummary: form.profileSummary,
      profileImg: form.profileImg,
    };
    try {
      setSaving(true);
      if (profileImg) {
        const frontSideResponse = await fileUpload(
          profileImg,
          form.id,
          "USER_PROFILE"
        );
        if (frontSideResponse.url) {
          payload.profileImg = frontSideResponse.url;
        }
      }

      const response = await api.saveUserProfile(provider.id, payload);
      setSaving(false);
      const result = response;
      if ([200, 201].includes(result.status)) {
        ShowToast({
          type: "success",
          message: "Profile Updated!",
        });
        const userResponse = await api.getUserProfile(provider.id);
        if (userResponse) {
          setUserInfo && setUserInfo(userResponse);
          setUser(userResponse);
        }
        onUpdate();
      }
    } catch (error: any) {
      setSaving(false);
      if (error?.response?.status === 401) {
        ShowToast({
          type: "error",
          message: "Invalid email or password",
        });
        return;
      }
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
      return false;
    }
  };

  useEffect(() => {
    setForm({
      ...provider,
      profession: provider.profession.join(", "),
      focusAreas: provider.focusAreas.filter((f: any) => f.trim()?.length > 0),
      practiceAreas: provider.practiceAreas.filter(
        (f: any) => f.trim()?.length > 0
      ),
      acceptedInsurances: provider.acceptedInsurances.filter(
        (f: any) => f.trim()?.length > 0
      ),
    });
  }, [provider]);
  const url = profileImg ? URL.createObjectURL(profileImg) : form.profileImg;

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])
  const [zipCity, zipState, zipFullState] = useMemo(() => {
    return isZipValid(form.addressZip) ? [zipCodes[form.addressZip]?.city, zipCodes[form.addressZip]?.state_short, zipCodes[form.addressZip]?.state] : [undefined, undefined];
  }, [form.addressZip]);

  const disabled = useMemo(() => {
    return (
      !form?.firstName ||
      !form?.lastName ||
      !form?.addressState ||
      !isZipValid(form.addressZip) ||
      form?.profession?.length === 0 ||
      form?.focusAreas?.length === 0 ||
      form?.practiceAreas?.length === 0 ||
      form?.acceptedInsurances?.length === 0
    );
  }, [form]);

  return (
    <>
      <div className="max-w-[820px] mx-auto flex flex-col mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <button onClick={onBack}>
              <ChevronLeft className="w-[30px] h-[30px]" />
            </button>
            <span className="text-black text-2xl font-bold">Edit Profile</span>
          </div>
          <Button
            variant="primary"
            className="!text-sm !font-medium !rounded-full"
            onClick={handleSubmit}
            disabled={disabled || saving}
            loading={saving}
          >
            <CheckIcon className="[&_path]:stroke-white" />
            Save Changes
          </Button>
        </div>
      </div>
      <div
        id="provider-scroll"
        className="max-h-[calc(100vh-150px)] lg:max-h-[calc(100vh-95px)] overflow-auto"
      >
        <div className="max-w-[820px] mx-auto flex flex-col gap-4">
          <div className="pt-flex p-5 bg-white rounded-2xl flex-col">
            <div className="text-black text-base font-semibold">
              Personal Details
            </div>
            <div className="border-b border-b-[#D4E1DD] w-full my-5" />
            <div className="items-start md:items-center gap-6 flex flex-col md:flex-row justify-between">
              <div className="flex gap-3.5 items-center">
                <input
                  type="file"
                  onChange={(e) => {
                    if (e.target.files?.[0]) {
                      setProfileImg(e.target.files[0]);
                      setShowCropModal(true);
                    }
                  }}
                  id="profileImg"
                  className="hidden"
                  accept="image/png, image/jpeg"
                  ref={(e) => (profileImgRef.current = e)}
                />
                {url ? (
                  <ImageView
                    className="w-24 h-24 rounded-[10px] object-cover"
                    src={url}
                    alt="user"
                    loading="lazy"
                  />
                ) : (
                  <div className="uppercase w-24 h-24 rounded-[10px] flex justify-center items-center text-3xl text-primary bg-secondary">
                    {getInitial(provider?.firstName, provider?.lastName)}
                  </div>
                )}
                <div className="flex flex-col gap-[14px]">
                  <button
                    onClick={() => profileImgRef.current?.click()}
                    className="text-primary text-base font-medium text-left"
                  >
                    Change Photo
                  </button>

                  {(profileImg || form.profileImg) && (
                    <button
                      onClick={() => handleCrop()}
                      className="text-primary text-base font-medium text-left"
                    >
                      Edit Photo
                    </button>
                  )}
                  {(form.profileImg || profileImg) && (
                    <button
                      onClick={() => {
                        setProfileImg(null);
                        setForm((prev) => ({ ...prev, profileImg: "" }));
                      }}
                      className="text-[#D12E2E] text-base font-medium font-['Outfit'] leading-none text-left"
                    >
                      Remove Photo
                    </button>
                  )}
                </div>
              </div>
              <div className="pl-4 pr-5 py-3.5 bg-white rounded-md border border-teal-500 border-opacity-25 justify-start items-center gap-1.5 inline-flex">
                <div className="inline-flex items-center">
                  <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                    <input
                      id="switch-component"
                      type="checkbox"
                      onChange={(e) =>
                        setForm((prev: typeof form) => ({
                          ...prev,
                          isAvailable: e.target.checked,
                        }))
                      }
                      className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-[#E8F3F2] checked:bg-primary"
                      checked={form.isAvailable}
                    />
                    <label
                      htmlFor="switch-component"
                      className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity before:shadow-[10px_14px_40px_0px_rgba(0,71,28,0.11)]  hover:before:opacity-0 peer-checked:translate-x-full peer-checked:before:bg-primary"
                    >
                      <div
                        className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                        data-ripple-dark="true"
                      ></div>
                    </label>
                  </div>
                </div>
                <div className="text-black text-sm font-normal font-['Outfit'] leading-tight">
                  Accepting New Clients
                </div>
              </div>
            </div>
            <div className="border-b border-b-[#D4E1DD] w-full my-5" />
            <div className="flex flex-col md:flex-row items-center justify-center gap-3 mb-5">
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">First Name</label>
                <TextInput
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  maxLength={128}
                />
              </div>
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">Last Name</label>
                <TextInput
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                  placeholder="Enter last name"
                  maxLength={128}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center gap-3  mb-1">
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">Role</label>
                <TextInput
                  name="profession"
                  value={form.profession}
                  onChange={handleChange}
                  placeholder="Enter role"
                  maxLength={128}
                />
              </div>
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label htmlFor="password" className="text-sm text-black/80">
                  Zip Code
                </label>
                <TextInput
                  name="addressZip"
                  value={form.addressZip}
                  onChange={handleChange}
                  placeholder="Enter Zip Code"
                />

              </div>
            </div>
            {zipCity && zipState &&
              <div className="flex flex-col md:flex-row items-center justify-center gap-3 mb-1 ml-32">
                <div className="text-xs">City: {zipCity}, {zipState}</div>
              </div>
            }
          </div>

          {/* <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <div className="text-black text-base font-semibold">Notifications</div>
            <div className="text-s"> Receive alerts about new messages and request status updates about Headstart Account.</div>

            Would you like to opt in to receive notifications via SMS?
          <div className="inline-block">
            <input
              id="optIn"
              type="checkbox"
              onChange={(e) =>
                setForm((prev: typeof form) => ({
                  ...prev,
                  isAvailable: e.target.checked,
                }))
              }
              className="w-8 h-4 transition-colors"
              checked={form.isAvailable}
            />
            <label
              htmlFor="optIn"
            >
              Yes, I would like to receive notifications via SMS.
            </label>
          </div>
        </div> */}

          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <div className="text-black text-base font-semibold">About Me</div>
            <TextArea
              name="profileSummary"
              value={form.profileSummary}
              onChange={handleChange}
              placeholder="Enter about me"
              maxLength={1000}
              rows={5}
            />
          </div>
          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <div className="text-black text-base font-semibold">
              My Focus Areas
            </div>
            <MultiSelect
              options={focusAreas}
              value={form.focusAreas || []}
              name="focusAreas"
              onChange={(selectedOptions) => {
                setForm((prev: any) => ({
                  ...prev,
                  focusAreas: selectedOptions.map((o) => o.value),
                }));
              }}
            />
          </div>
          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <div className="text-black text-base font-semibold">
              Accepted Insurance
            </div>
            <MultiSelect
              options={acceptedInsurances.filter(insurance => insurance.states.includes(zipFullState))}
              value={form.acceptedInsurances || []}
              name="acceptedInsurances"
              onChange={(selectedOptions) => {
                setForm((prev: any) => ({
                  ...prev,
                  acceptedInsurances: selectedOptions.map((o) => o.value),
                }));
              }}
            />
          </div>

          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <div className="text-black text-base font-semibold">
              Where I Practice
            </div>

            <div className="border border-[#8D8E92] rounded-md p-3.5">
              <ChipInput
                chips={form.practiceAreas || []}
                placeholder="Enter Practice Areas"
                onSubmit={(chip: string) => {
                  if (
                    chip.trim() !== "" &&
                    !(form.practiceAreas || []).includes(chip)
                  ) {
                    setForm((prev: typeof form) => ({
                      ...prev,
                      practiceAreas: [...(prev.practiceAreas || []), chip],
                    }));
                  }
                }}
                onRemove={(index: number) =>
                  setForm((prev: typeof form) => ({
                    ...prev,
                    practiceAreas: (prev.practiceAreas || []).filter(
                      (_, i) => i !== index
                    ),
                  }))
                }
              />
            </div>
          </div>
          <div className="my-8">
            <FooterAction onLogout={onLogout} />
          </div>
        </div>
      </div >

      {showCropModal && (
        <ImageCrop
          isOpen={showCropModal}
          onClose={() => {
            setShowCropModal(false);
          }}
          image={profileImg || form.profileImg}
          onImageChange={({ url, file }) => setProfileImg(file)}
        />
      )
      }
    </>
  );
}
