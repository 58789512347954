import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useFiles } from "./context/file-context";
import { ReactComponent as GridIcon } from "../../../assets/icons/grid.svg";
import { ReactComponent as ListIcon } from "../../../assets/icons/list.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as RightIcon } from "../../../assets/icons/right-chevron.svg";
import { ReactComponent as FileIcon } from "../../../assets/icons/file.svg";
import { ListView } from "./components/ListView";
import { GridView } from "./components/GridView";
import { Button } from "../../../components/Button";
import { CreateFolderDialog } from "./components/CreateFolderDialog";
import { UploadFilesDialog } from "./components/UploadFilesDialog";
import React from "react";
import { LoadingSkeleton } from "./components/Document";
import { DOCUMENT_OWNER_TYPES } from "../../../constant";

export function Library() {
  const {
    fileLoading,
    files,
    currentFolder,
    searchKeyword,
    onSearch,
    breadcrumb,
    onBreadcrumbClick,
    fetchFiles,
  } = useFiles() as any;

  const [activeView, setActiveView] = useState("list"); // ["list", "grid"]
  const [createNewFolderDialog, setCreateNewFolderDialog] = useState(false);
  const [uploadFileDialog, setUploadFileDialog] = useState(false);
  const [searchMode, setSearchMode] = useState(false);

  const handleSearchChange = (e: any) => {
    // need to clear current folder and search for files
    // need to clear breadcrumb
    onSearch(e.target.value);
  };
  return (
    <div>
      <div className="mb-0 md:mb-7 flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between lg:items-center">
        <div className="flex justify-between lg:justify-start lg:items-center gap-5">
          <span className="text-black mt-2 ml-3 sm:ml-0 sm:mt-0 text-xl sm:text-3xl lg:text-2xl font-bold ">My Library</span>
          <div className="lg:hidden inline-block text-center mx-auto mt-3 cursor-pointer" onClick={() => {
            onSearch('')
            if (searchMode) {
              setSearchMode(false)
            } else {
              setSearchMode(true)
            }
          }}>
            {!searchMode ? <>
              <SearchIcon className="inline-block w-5 h-5 [&_path]:stroke-primary" style={{ transform: "translate(-5px, -2px)" }} />
              <div className="inline-block text-primary text-base font-bold">Search</div>
            </> : <>
              <CloseIcon className="inline-block w-5 h-5 [&_path]:stroke-primary" style={{ transform: "translate(-2px, -1px)" }} />
              <div className="inline-block text-primary text-base font-semibold">Close</div>
            </>}
          </div>
          <div className="hidden lg:block">
            {/** search bar */}
            <div className="relative">
              <div className="absolute left-3 top-4">
                <SearchIcon className="w-5 h-5 text-black/60 [&_path]:stroke-[#8D8E92]" />
              </div>
              <input
                type="text"
                value={searchKeyword}
                onChange={handleSearchChange}
                placeholder="Search"
                className="w-full max-w-[170px] py-3 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              />
            </div>
          </div>
          <div className="p-1 bg-white rounded-3xl shadow justify-start items-start gap-0.5 flex lg:hidden">
            <button
              onClick={() => setActiveView("list")}
              className={`px-3 py-2 ${activeView === "list"
                ? "bg-[#C6F7F2]"
                : "hover:bg-[#C6F7F2]/30 bg-white opacity-70"
                }  rounded-3xl justify-start items-start gap-2.5 flex`}
            >
              <ListIcon className="w-6 h-6" />
            </button>
            <button
              onClick={() => setActiveView("grid")}
              className={`px-3 py-2 ${activeView === "grid"
                ? "bg-[#C6F7F2]"
                : "hover:bg-[#C6F7F2]/30 bg-white opacity-70"
                } rounded-3xl justify-start items-start gap-2.5 flex`}
            >
              <GridIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="flex gap-2.5 justify-end flex-wrap">
          {searchMode &&
            <div className="lg:hidden w-full max-w-[350px] mx-auto mb-3 md:mb-0">
              {/** search bar */}
              <div className="relative">
                <div className="absolute left-3 top-4">
                  <SearchIcon className="w-5 h-5 text-black/60 [&_path]:stroke-[#8D8E92]" />
                </div>
                <input
                  type="text"
                  value={searchKeyword}
                  onChange={handleSearchChange}
                  placeholder="Search"
                  className="w-full py-3 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                />
              </div>
            </div>
          }
          {!searchMode &&
            <div className="mx-auto lg:w-auto flex items-center gap-2 mb-2">
              <div className="p-1 bg-white rounded-3xl shadow justify-start items-start gap-0.5 hidden lg:flex">
                <button
                  onClick={() => setActiveView("list")}
                  className={`px-3 py-2 ${activeView === "list"
                    ? "bg-[#C6F7F2]"
                    : "hover:bg-[#C6F7F2]/30 bg-white opacity-70"
                    }  rounded-3xl justify-start items-start gap-2.5 flex`}
                >
                  <ListIcon className="w-6 h-6" />
                </button>
                <button
                  onClick={() => setActiveView("grid")}
                  className={`px-3 py-2 ${activeView === "grid"
                    ? "bg-[#C6F7F2]"
                    : "hover:bg-[#C6F7F2]/30 bg-white opacity-70"
                    } rounded-3xl justify-start items-start gap-2.5 flex`}
                >
                  <GridIcon className="w-6 h-6" />
                </button>
              </div>

              <Button
                onClick={() => setCreateNewFolderDialog(true)}
                variant="primary"
                className="!rounded-full flex-1 !whitespace-nowrap text-xs"
                disabled={currentFolder?.ownerType === DOCUMENT_OWNER_TYPES.ADMIN}
              >
                <PlusIcon className="w-3 h-3 lg:w-6 lg:h-6 [&_path]:stroke-white" />
                Create Folder
              </Button>
              <Button
                onClick={() => setUploadFileDialog(true)}
                variant="primary"
                className="!rounded-full flex-1 !whitespace-nowrap text-xs"
                disabled={currentFolder?.ownerType === DOCUMENT_OWNER_TYPES.ADMIN}
              >
                <PlusIcon className="w-3 h-3 lg:w-6 lg:h-6  [&_path]:stroke-white" />
                Upload
              </Button>
            </div>
          }

        </div>
      </div>
      <div className="flex items-center flex-wrap gap-2">
        {searchKeyword && (
          <>
            <button
              onClick={() => onSearch("")}
              className="text-primary hover:text-black font-medium ml-5 text-base md:text-lg"
            >
              Search
            </button>
            <div>
              <RightIcon className="w-6 h-6" />
            </div>
            {breadcrumb.length === 0 && (
              <div className="text-black text-base md:text-lg font-medium">
                {searchKeyword ? "Results" : "My Library"}
              </div>
            )}
          </>
        )}
        {breadcrumb.length > 0 && (
          <button onClick={() => onBreadcrumbClick(-1)}>
            <div className="text-primary text-base md:text-lg font-medium">
              {searchKeyword ? "Results" : "My Library"}
            </div>
          </button>
        )}
        {breadcrumb.map((item: any, index: number) => (
          <React.Fragment key={item.id}>
            <div>
              <RightIcon className="w-6 h-6" />
            </div>
            <button
              disabled={breadcrumb.length - 1 === index}
              key={item.id}
              onClick={() => onBreadcrumbClick(index)}
            >
              {breadcrumb.length - 1 === index ? (
                <div className="text-black text-base md:text-lg font-medium">
                  {item.name}
                </div>
              ) : (
                <div className="text-primary text-base md:text-lg font-medium">
                  {item.name}
                </div>
              )}
            </button>
          </React.Fragment>
        ))}
      </div>
      <div className="mt-3 md:mt-5">
        {fileLoading ? (
          <LoadingSkeleton type={activeView} />
        ) : !fileLoading && files.length === 0 ? (
          <div className="flex flex-col justify-center items-center h-[calc(100vh-400px)] md:h-[calc(100vh-350px)] lg:h-[calc(100vh-250px)]">
            <div className="bg-[#E0ECE8] p-5 rounded-full">
              <FileIcon className="w-24 h-24 [&_path]:stroke-primary [&_polyline]:stroke-primary" />
            </div>
            <div className="text-center mt-6">No Folder/File Found</div>
          </div>
        ) : (
          <>
            {activeView === "list" ? (
              <ListView data={files || []} />
            ) : (
              <GridView data={files || []} />
            )}
          </>
        )}
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {createNewFolderDialog && (
          <CreateFolderDialog onClose={() => setCreateNewFolderDialog(false)} />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {uploadFileDialog && (
          <UploadFilesDialog onClose={() => { setUploadFileDialog(false); fetchFiles(); }} />
        )}
      </AnimatePresence>
    </div>
  );
}
