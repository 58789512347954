import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import DatePickerComponent from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export function DatePicker({
  value,
  onChange,
  className = "",
  minDate,
  maxDate,
  disabled,
  minHeight
}: {
  value: any;
  onChange: (value: any) => void;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  minHeight?: string;
}) {
  return (
    <div className={`date-picker ${className}`}>
      <DatePickerComponent
        onChange={onChange}
        selected={value}
        icon={
          <div className="mt-2">
            <CalendarIcon />
          </div>
        }
        showIcon={true}
        placeholderText="mm/dd/yyyy"
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        popperPlacement="bottom"
        className={`!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus-within:border-grey2 focus:border-grey2 text-xs sm:text-sm md:text-base text-black [&>div]:!border-none h-14 ml-1`}
        style={{ minHeight: minHeight ?? '50px' }}
      />
    </div>
  );
}
