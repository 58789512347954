import { useEffect, useMemo, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { Button } from "../../../components/Button";
import { SideModal } from "../../../components/SideModal";
import { ReactComponent as FilledCheckboxIcon } from "../../../assets/icons/checkbox-filled.svg";
import ChevronDown from "../../../assets/icons/chevron-down.svg";

import { SelectInput } from "../../../components/SelectInput";
import { getLocationListForSelect } from "../../../services/utils";
import { acceptedInsurances, focusAreas } from "../../../constant";

const providerTypeList = [
  "MS",
  "BCBA",
  "LBA",
  "OT",
  "SLP",
  "ST",
  "MA",
  "Doctorate",
];

export function FilterModal({ onClose, filters, setFilters }) {
  const [form, setForm] = useState({ ...filters });
  const handleClose = () => {
    onClose();
  };

  const handleChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === "showAvailable") {
      setForm((prev) => ({ ...prev, [name]: checked }));
    } else if (["focusAreas", "acceptedInsurances"].includes(name)) {
      setForm((prev) => ({ ...prev, [name]: [value] }));
    } else if (name === "focusAreas") {
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleProviderTypeChange = (providerType) => {
    setForm((prev) => {
      if (prev.providerTypes.includes(providerType)) {
        return {
          ...prev,
          providerTypes: prev.providerTypes.filter(
            (item) => item !== providerType
          ),
        };
      }
      return {
        ...prev,
        providerTypes: [...prev.providerTypes, providerType],
      };
    });
  };

  const handleApply = () => {
    setFilters(form);
    onClose();
  };

  const handleReset = () => {
    const payload = {
      showAvailable: false,
      providerTypes: [],
      focusAreas: [],
      addressState: "",
    };
    setForm({
      showAvailable: false,
      providerTypes: [],
      focusAreas: [],
      addressState: "",
    });
    if (JSON.stringify(filters) !== JSON.stringify(payload)) {
      setFilters({
        showAvailable: false,
        providerTypes: [],
        focusAreas: [],
        addressState: "",
      });
    }
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
  };

  useEffect(() => {
    setForm(filters);
  }, [filters]);

  useEffect(() => {
    disableScroll();
    return () => enableScroll();
  }, []);

  const isChanged = useMemo(() => {
    // check if any field changed or not
    if (JSON.stringify(form) !== JSON.stringify(filters)) {
      return true;
    }
    return false;
  }, [form, filters]);

  const isEmpty = useMemo(() => {
    if (
      form.showAvailable ||
      form.providerTypes.length > 0 ||
      form.focusAreas.length > 0 ||
      form.addressState
    ) {
      return false;
    }
    return true;
  }, [form]);

  return (
    <>
      <SideModal onClose={handleClose}>
        <div className="h-full w-full lg:w-[520px] relative bg-white flex flex-col">
          <div className="w-full p-6 border-b border-neutral-200 justify-between items-center flex">
            <div className="text-neutral-800 text-xl font-bold  leading-snug">
              Filters
            </div>
            <button type="button" onClick={handleClose}>
              <CloseIcon className="w-6 h-6 relative" />
            </button>
          </div>
          <div className="flex-1 w-full flex-col justify-start items-start gap-9 p-6 flex overflow-auto">
            <div className="w-full flex-col justify-start items-start gap-2.5 flex">
              <div className="opacity-80 text-black text-base font-semibold ">
                State
              </div>
              <SelectInput
                data={[...getLocationListForSelect()]}
                value={form.addressState}
                disabledOptionText="Select State"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    addressState: e.target.value,
                  }))
                }
              />
            </div>
            <div className="w-full flex-col justify-start items-start gap-2.5 flex">
              <div className="opacity-80 text-black text-base font-semibold ">
                Availability
              </div>

              <label
                htmlFor="availableProvidersOnly"
                className="cursor-pointer group w-full p-4 opacity-80 bg-white rounded-lg border border-gray-300 justify-start items-center gap-2.5 inline-flex"
              >
                <div className="flex">
                  <input
                    type="checkbox"
                    id="availableProvidersOnly"
                    name="showAvailable"
                    checked={form.showAvailable}
                    onChange={handleChange}
                    className={`w-5 h-5 cursor-pointer w-5 h-5 rounded border border-[#8D8E92] accent-[#14b8a6] ${form.showAvailable ? "opacity-[0] !h-0 !w-0" : ""
                      }`}
                  />
                  {form.showAvailable ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        handleChange({
                          target: {
                            name: "showAvailable",
                            checked: false,
                          },
                        });
                      }}
                    >
                      <FilledCheckboxIcon className="w-5 h-5" />
                    </button>
                  ) : null}
                </div>
                <label
                  htmlFor="availableProvidersOnly"
                  className="cursor-pointer group-hover:!text-black/80 text-black text-base font-normal "
                >
                  Show only available providers
                </label>
              </label>
            </div>
            <div className="flex-col justify-start items-start gap-2.5 flex">
              <div className="opacity-80 text-black text-base font-semibold ">
                Provider Type
              </div>
              <div className="justify-start items-start gap-2.5 flex flex-wrap">
                {providerTypeList.map((providerType, i) => (
                  <button
                    type="button"
                    key={providerType}
                    onClick={() => handleProviderTypeChange(providerType)}
                    className={`px-6 py-4 opacity-80 ${form.providerTypes.includes(providerType)
                      ? "bg-emerald-100 border border-teal-500"
                      : "bg-emerald-50"
                      } rounded-lg  justify-start items-center gap-2.5 flex`}
                  >
                    <div className="text-black text-base font-normal ">
                      {providerType}
                    </div>
                  </button>
                ))}
              </div>
            </div>
            <div className="w-full flex-col justify-start items-start gap-2.5 flex">
              <div className="opacity-80 text-black text-base font-semibold ">
                Focus Areas
              </div>
              <select
                value={form?.focusAreas?.[0]}
                className="w-full p-4 opacity-80 bg-white rounded-lg appearance-none border border-gray-300 justify-between items-center flex max-w-[calc(100%-8px)] md:max-w-auto"
                onChange={handleChange}
                name="focusAreas"
                style={{
                  backgroundImage: `url(${ChevronDown})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "97% 55%",
                }}
              >
                <option value="">Select a focus area</option>
                {focusAreas.map((focusArea, i) => (
                  <option value={focusArea.value} key={i}>
                    {focusArea.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full flex-col justify-start items-start gap-2.5 flex">
              <div className="opacity-80 text-black text-base font-semibold ">
                Accepted Insurances
              </div>
              <select
                value={form?.acceptedInsurances?.[0]}
                className="w-full p-4 opacity-80 bg-white appearance-none rounded-lg border border-gray-300 justify-between items-center flex max-w-[calc(100%-8px)] md:max-w-auto"
                onChange={handleChange}
                name="acceptedInsurances"
                style={{
                  backgroundImage: `url(${ChevronDown})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "97% 55%",
                }}
                disabled={!form.addressState || (acceptedInsurances.filter(insurance => insurance.states.includes(form.addressState)).length === 0)}
              >
                <option value="">{form.addressState ? ((acceptedInsurances.filter(insurance => insurance.states.includes(form.addressState)).length === 0) ? 'No insurance available for State' : 'Select an insurance') : 'Please select a state'}</option>
                {acceptedInsurances.filter(insurance => insurance.states.includes(form.addressState)).map((acceptedInsurance, i) => (
                  <option value={acceptedInsurance.value} key={i}>
                    {acceptedInsurance.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="w-full px-5 py-3 border-t border-neutral-200 justify-end items-center gap-3 inline-flex">
            <Button
              variant="primary"
              className={`px-9 py-4 ${!isEmpty ? "hover:!bg-[#C4F3E5]/60 !bg-[#C4F3E5]" : ""
                } !rounded-full border border-teal-500 border-opacity-20 justify-center items-center gap-2 flex`}
              onClick={handleReset}
              disabled={isEmpty}
            >
              <div className="text-black text-base font-medium ">Reset</div>
            </Button>
            <Button
              variant="primary"
              onClick={handleApply}
              disabled={!isChanged}
              className="px-9 py-4 bg-teal-500 !rounded-full border border-teal-500 border-opacity-20 justify-center items-center gap-2 flex"
            >
              <div className="text-white text-base font-medium">
                Apply Filters
              </div>
            </Button>
          </div>
        </div>
      </SideModal>
    </>
  );
}
