import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/profile.svg";
import { ReactComponent as MapIcon } from "../../../assets/icons/map.svg";
import { Button } from "../../../components/Button";
import { ImageView } from "../../../components/Image";
import { getInitial, getStateOrCity } from "../../../services/utils";
import { Tooltip } from "react-tooltip";
import { useMemo } from "react";
import { zipCodes } from "../../../assets/data/zip_codes";

const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])

export const SkeletonView = () => {
  return (
    <div className="animate-pulse flex-1 flex flex-col gap-4">
      <div className="p-5 bg-white rounded-3xl border border-neutral-300 gap-2 flex flex-col">
        <div className="justify-start items-start gap-6 flex">
          <div className="w-[100px] md:w-[183px] h-[100px] md:h-[183px] rounded-2xl bg-neutral-200" />
          <div className="flex-col justify-start items-start gap-4 inline-flex">
            <div className="flex-col justify-start items-start gap-2.5 mt-1 flex">
              <div className="justify-start items-center gap-2.5 flex">
                <div className="w-32 h-6 bg-neutral-200 rounded-3xl" />
                <div className="w-32 h-6 bg-neutral-200 rounded-3xl" />
              </div>
              <div className="w-48 h-6 bg-neutral-200 rounded-3xl" />
            </div>
            <div className="justify-start items-start gap-1.5 inline-flex">
              <div className="w-6 h-6 bg-neutral-200 rounded-full" />
              <div className="w-32 h-6 bg-neutral-200 rounded-3xl" />
            </div>
            <div className="w-48 h-12 bg-neutral-200 rounded-3xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

function ProviderIntroMobileView({ provider, onSchedule, onView }) {

  const [zipCity, zipState] = useMemo(() => {
    return isZipValid(provider.addressZip) ? [zipCodes[provider.addressZip]?.city, zipCodes[provider.addressZip]?.state] : [undefined, undefined];
  }, [provider.addressZip]);
  const addressZip = provider?.addressZip

  return (
    <>
      <div
        role="button"
        onClick={() => onView(provider)}
        className="p-5 bg-white rounded-3xl border border-neutral-300 gap-2 flex w-[calc(100%-20px)] md:w-[750px] lg:hidden flex-col"
      >
        <div className="items-start md:items-center gap-4 pb-1.5 flex">
          {provider?.profileImg ? (
            <ImageView
              className="w-[102px] h-[102px] rounded-2xl object-cover"
              src={provider.profileImg || "/images/profile1.png"}
              alt="provider"
              loading="lazy"
            />
          ) : (
            <div className="uppercase w-[102px] h-[102px] rounded-2xl flex justify-center items-center text-[40px] text-primary bg-secondary">
              {getInitial(provider?.firstName, provider?.lastName)}
            </div>
          )}
          <div className="flex-1 justify-between gap-2 md:gap-1 items-start md:items-center flex flex-col md:flex-row">
            <div className="flex-col justify-start items-start gap-1.5 flex">
              <div className={`flex-col justify-start items-start ${provider?.distanceFromZip ? '' : 'gap-1.5 mt-2'} flex inline-block`}>
                <div className="justify-start items-start gap-2.5 flex flex-col md:flex-row inline-block ">
                  <div className="text-black text-lg font-semibold inline-block ">
                    {provider.firstName} {provider.lastName}
                    {provider?.isAvailable && (
                      <div className="inline-block ml-3">
                        <div className="inline-block pl-1 pr-2 md:px-3 bg-white rounded-3xl border border-teal-500 border-opacity-25 justify-start items-center gap-0.5 flex">
                          <CheckIcon className="w-4 h-4" />
                          <div className="text-black text-xs  md:text-sm  font-normal ">
                            Available
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                </div>
                <div className={`text-black text-sm font-normal ${provider?.distanceFromZip ? '' : 'mb-1'}`}>
                  {provider?.profession?.join(", ")}
                </div>
              </div>

              {provider?.distanceFromZip &&
                <div className="justify-start items-start gap-1.5 inline-flex">
                  <LocationIcon className="w-[18px] h-[18px] justify-center items-center flex" />
                  <div className="text-black text-sm font-normal ">
                    {`${provider?.distanceFromZip ? `${provider?.distanceFromZip} miles away` : ''}`}
                  </div>
                </div>
              }

              <div className="justify-start items-start gap-1.5 inline-flex">
                <MapIcon className="w-[18px] h-[18px] justify-center items-center flex" />
                <div className="text-black text-sm font-normal ">
                  {`${zipCity ? `${zipCity}, ${zipState}` : ''} ${addressZip ? ` (${addressZip})` : `${provider?.addressState}`}`}
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="line-clamp-2 mb-[-30px] sm:mb-[-25px]">
          {provider?.profileSummary}
        </div>

        <div className="inline-block mb-[-30px] text-center">
          <div className="inline-block">
            <Button
              variant="primary"
              className="py-[10px] px-0 !rounded-full w-[160px] sm:w-[200px]"
              onClick={onSchedule}
              disabled={!provider?.isAvailable}
              data-tooltip-id={`my-tooltip-${provider.id}`}
              data-tooltip-content={
                !provider?.isAvailable
                  ? `${provider.firstName} ${provider.lastName} is not accepting new clients at the moment`
                  : ""
              }
            >
              <CalendarIcon className="hidden sm:block w-5 h-5 px-0 mx-0 relative [&>path]:fill-white" />
              <div className="text-white text-xs">
                Schedule Consultation
              </div>
            </Button>
          </div>
          <div className="inline-block ml-3">
            <Button
              variant="outline"
              type="button"
              className={`py-[10px] px-0 !rounded-full border justify-center items-center gap-1.5 w-[120px] sm:w-[160px] hover:bg-gray-300 hover:text-white-300 mt-8 mb-6`}
              onClick={() => onView(provider)}
            >
              <div className="hidden sm:block w-5 h-5 justify-center items-center flex">
                <ProfileIcon className="mt-[2px] w-4 h-4 relative" />
              </div>
              <div className="text-black text-opacity-80 text-xs">
                View Profile
              </div>
            </Button>
          </div>

          <Tooltip
            id={`my-tooltip-${provider.id}`}
            className="!w-[228px] !border-0 !text-black !rounded-lg !p-4 !bg-white"
            style={{
              boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.21)",
            }}
            noArrow
          />
        </div>

      </div>
    </>
  );
}

export function ProviderCard({ provider, onClick, onView }) {
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  };


  const [zipCity, zipState] = useMemo(() => {
    return isZipValid(provider.addressZip) ? [zipCodes[provider.addressZip]?.city, zipCodes[provider.addressZip]?.state] : [undefined, undefined];
  }, [provider.addressZip]);
  const addressZip = provider?.addressZip


  return (
    <>
      <div
        role="button"
        onClick={() => onView(provider)}
        className="py-5 pl-5 pr-5 bg-white rounded-3xl border border-neutral-300 gap-2 lg:flex lg:w-[1010px] hidden flex-col"
      >

        <div>
          <div className="justify-start items-start gap-6 flex">
            {provider?.profileImg ? (
              <ImageView
                className="w-[100px] md:w-[183px] h-[100px] md:h-[183px] rounded-2xl object-cover"
                src={provider?.profileImg || "/images/profile1.png"}
                alt="provider"
                loading="lazy"
              />
            ) : (
              <div className="uppercase w-[100px] md:w-[183px] h-[100px] md:h-[183px] rounded-2xl flex justify-center items-center text-[80px] text-primary bg-secondary">
                {getInitial(provider?.firstName, provider?.lastName)}
              </div>
            )}
            <div className={`flex-1 flex-col justify-start items-start ${provider?.distanceFromZip ? 'gap-2' : 'gap-4'} inline-flex`}>
              <div className="flex-col justify-start items-start gap-2.5 flex">
                <div className="justify-start items-center gap-2.5 flex">
                  <div className="text-black text-2xl font-semibold">
                    {provider?.firstName} {provider?.lastName}
                  </div>
                  {provider?.isAvailable && (
                    <div className="pl-2.5 pr-3 py-1.5 bg-white rounded-3xl border border-[rgba(0,174,155,0.24)] justify-start items-center gap-0.5 flex">
                      <CheckIcon className="w-4 h-4 relative" />
                      <div className="text-black text-sm font-normal">
                        Available
                      </div>
                    </div>
                  )}
                </div>
                <div className="text-black text-base font-normal">
                  {provider?.profession?.join(", ")}
                </div>
              </div>
              {provider?.distanceFromZip &&
                <div className="justify-start items-start gap-1.5 inline-flex">
                  <div className="w-6 h-6 justify-center items-center flex">
                    <div className="w-6 h-6 justify-center items-center flex">
                      <LocationIcon className="w-6 h-6" />
                    </div>
                  </div>
                  <div className="text-black text-base font-normal">
                    {`${provider?.distanceFromZip ? `${provider?.distanceFromZip} miles away` : ''}`}
                  </div>
                </div>
              }
              <div className="justify-start items-start gap-1.5 inline-flex">
                <div className="w-6 h-6 justify-center items-center flex">
                  <div className="w-6 h-6 justify-center items-center flex">
                    <MapIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="text-black text-base font-normal">
                  {`${zipCity ? `${zipCity}, ${zipState}` : ''} ${addressZip ? ` (${addressZip})` : `${provider?.addressState}`}`}
                </div>
              </div>

              <div className="line-clamp-2 w-[530px]">
                {provider?.profileSummary}
              </div>

            </div>

            <div >
              <Button
                variant="outline"
                type="button"
                className={`hidden md:flex px-5 py-3.5 !rounded-full border justify-center items-center gap-1.5 w-[210px] hover:bg-gray-300 hover:text-white-300 mt-8 mb-6`}
                onClick={() => onView(provider)}
              >
                <div className="w-4 h-4 justify-center items-center flex">
                  <ProfileIcon className="w-5 h-5 relative" />
                </div>
                <div className="text-black text-opacity-80 text-sm font-medium">
                  View Profile
                </div>
              </Button>

              <Button
                variant="primary"
                type="button"
                className={`hidden md:flex px-5 py-3.5 bg-[#E5EFEC] hover:bg-[#ffffff] hover:border-primary !rounded-full border justify-center items-center gap-1.5 w-[210px] ${!provider?.isAvailable &&
                  `opacity-40 ${provider.id}-anchor-element`
                  }`}
                onClick={handleClick}
                disabled={!provider?.isAvailable}
                data-tooltip-id={`my-tooltip-${provider.id}`}
                data-tooltip-place="right"
                data-tooltip-content={
                  !provider?.isAvailable
                    ? `${provider.firstName} ${provider.lastName} is not accepting new clients at the moment`
                    : ""
                }
              >
                <div className="w-5 h-5 justify-center items-center flex">
                  <CalendarIcon className="w-5 h-5 relative [&>path]:fill-white" />
                </div>
                <div className="text-white text-opacity-100 text-sm font-medium">
                  Schedule Consultation
                </div>
              </Button>
              <Tooltip
                place='right'
                id={`my-tooltip-${provider.id}`}
                className="!w-[228px] !border-0 !text-black !rounded-lg !p-4 !bg-white"
                noArrow
                style={{
                  boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.21)",
                }}
              />
            </div>

          </div>
        </div>

      </div>
      <ProviderIntroMobileView
        provider={provider}
        onSchedule={handleClick}
        onView={onView}
      />
    </>
  );
}
