import React, { useEffect, useMemo, useState } from "react";
import { api } from "../../../../services/api";
import { getUser } from "../../../../services/utils";
import { AuthRequest, AuthRequestClient } from "../RequestAuth.types";

export type AuthRequestProviderProps = {
  authRequests: Array<AuthRequest>;
  allPatients: Array<AuthRequestClient>;
  refreshRequests: () => {};
  refreshAllPatients: () => {};
}

export const RequestAuthContext = React.createContext<AuthRequestProviderProps>({
  authRequests: [],
  allPatients: [],
  refreshRequests: () => ({}),
  refreshAllPatients: () => ({}),
});

export function AuthRequestProvider({ children }) {
  const user = getUser();
  const [requests, setRequests] = useState<Array<AuthRequest>>([]);
  const [allPatients, setAllPatients] = useState<Array<AuthRequestClient>>([]);

  const fetchRequests = useMemo(() => async () => {
    setRequests(await api.getAuthRequests())
  }, []);

  const fetchAllPatients = useMemo(() => async () => {
    setAllPatients(await api.getAllPatients())
  }, []);

  useEffect(() => {
    if (user?.id && user?.type === 'P') {
      fetchRequests();
      fetchAllPatients();
    }
  }, [user?.id]);

  const values = useMemo(() => ({
    authRequests: requests,
    refreshRequests: fetchRequests,
    refreshAllPatients: fetchAllPatients,
    allPatients: allPatients
  }), [requests, fetchRequests, allPatients, fetchAllPatients]);

  return <RequestAuthContext.Provider value={values}>{children}</RequestAuthContext.Provider>;
}

export function useAuthRequests() {
  const context = React.useContext<AuthRequestProviderProps>(RequestAuthContext);
  if (context === undefined) {
    throw new Error("useAuthRequests must be used within a AuthRequestProvider");
  }
  return context;
}
