import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success.svg";

export function QrCodeSuccess() {
  return (
    <div className="flex flex-col items-center justify-center my-0">
      <SuccessIcon className="w-[250px] h-[250px]" />
      <div className="font-bold text-[19px] text-black">Photo Uploaded</div>
    </div>
  );
}
