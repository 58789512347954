import { useEffect, useState } from "react";
import { useAuthRequests } from "./context/RequestAuthContext";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down-green.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { Button } from "../../../components/Button/Button";
import moment from "moment";
import { AnimatePresence } from "framer-motion";
import { AuthRequestModal } from "./modals/AuthRequestModal";
import { AuthRequest, AuthRequestStatus, authRequestStatusColor, authRequestStatusText } from "./RequestAuth.types";

export function RequestAuthList() {
  const { authRequests, refreshRequests, refreshAllPatients } = useAuthRequests();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [authRequestToEdit, setAuthRequestToEdit] = useState<AuthRequest | undefined>();

  useEffect(() => {
    refreshRequests()
  }, [])

  const openCreateModal = () => {
    refreshAllPatients();
    setShowCreateModal(true)
  }

  const ExpandableRequestsList = ({ requests, title, defaultOpen }: { requests: AuthRequest[], title: string, defaultOpen: boolean }) => {
    const [expanded, setExpanded] = useState(defaultOpen);

    const fullTitle = `${title} Request${requests.length > 1 ? 's' : ''} (${requests.length})`
    const chevron = (expanded) ? <ChevronRight className="inline-block mr-1" style={{ width: "25px", height: "25px", marginBottom: "3px" }} /> :
      <ChevronDown className="inline-block mr-1" style={{ width: "25px", height: "25px", marginBottom: "3px" }} />

    const noRequestsMessage = <div className="pb-10">
      <div className="text-center mt-3 pb-3">You have no {title.toLowerCase()} requests</div>
      {defaultOpen && <Button
        variant="primary"
        className="!rounded-full !whitespace-nowrap mx-auto"
        onClick={openCreateModal}
      >
        <PlusIcon className="[&_path]:stroke-white" />
        Request Auth
      </Button>}
    </div>

    const orderedRequests = requests.sort((a, b) => (b.createdAt ? new Date(b.createdAt).getTime() : new Date().getTime()) - (a.createdAt ? new Date(a.createdAt).getTime() : new Date().getTime()));

    return <div className="w-full bg-white my-10 rounded-lg" >
      <div className="inline-block text-sm md:text-base lg:text-lg cursor-pointer w-full ml-3 my-2" onClick={() => setExpanded(!expanded)}>
        {chevron}
        {fullTitle}
      </div>
      {expanded && <>
        {orderedRequests.length === 0 ? noRequestsMessage : <>
          <table className="w-full text-xs md:text-sm lg:text-base">
            {/* <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
            </colgroup> */}
            <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
              <tr className="sticky top-0 z-[1]">
                <td className="rounded-l pl-5">
                  <div className="items-center">
                    <span className="hidden md:block">Request #</span>
                    <span className="block md:hidden">#</span>
                  </div>
                </td>
                <td>
                  <div className="items-center text-center">
                    <span className="hidden md:block">Submitted Date</span>
                    <span className="block md:hidden">Submitted</span>
                  </div>
                </td>
                <td>
                  <div className="items-center text-center">
                    <span className="hidden md:block">Authorization Type</span>
                    <span className="block md:hidden">Auth Type</span>

                  </div>
                </td>
                <td>

                  <div className="items-center text-center">
                    <span className="hidden md:block">Client Name</span>
                    <span className="block md:hidden">Client</span>
                  </div>
                </td>
                <td>
                  <div className="items-center text-center">
                    Status
                  </div>
                </td>
                <td className="rounded-r"></td>
              </tr>
            </thead>
            <tbody>
              {orderedRequests.map(request => <>
                <tr
                  key={request.friendlyId}
                  className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                  onClick={() => setAuthRequestToEdit(request)}
                >
                  <td className="name pl-5">
                    <div className="inline-block">
                      <h1>{request.friendlyId}</h1>
                    </div>
                  </td>

                  <td className="mrr text-center">
                    <span className="hidden md:block">{moment.utc(request.createdAt).local().format("MMM DD,YYYY")}</span>
                    <span className="block md:hidden">{moment.utc(request.createdAt).local().format("MM/DD")}</span>
                  </td>
                  <td className="name">
                    <div className="text-center">
                      <h5 className="">
                        {request.type && `${request.type.charAt(0).toUpperCase()}${request.type.slice(1).toLowerCase()} `}
                        <span className="hidden md:block">Authorization</span>
                      </h5>
                    </div>
                  </td>
                  <td className="name">
                    <div className="text-center">
                      <h5 className="">
                        <span className="hidden md:block">{request.client?.firstName} {request.client?.lastName}</span>
                        <span className="block md:hidden">{request.client?.firstName?.[0]}. {request.client?.lastName}</span>
                      </h5>
                    </div>
                  </td>
                  <td className="name">
                    <div className="text-center">
                      <h5 className="" style={request.status && { color: authRequestStatusColor(request.status) }}>

                        <span className="hidden md:block">{authRequestStatusText(request?.status)}</span>
                        <span className="block md:hidden">{authRequestStatusText(request?.status).replaceAll('Insurance', 'Ins.')}</span>
                      </h5>
                    </div>
                  </td>
                </tr >
              </>)}
            </tbody>
          </table>
        </>}
      </>
      }
    </div >
  }


  return (
    <div>
      <div className="mb-7 flex flex-col gap-5">
        <div className="flex justify-between items-center gap-5">
          <span className="text-black text-3xl font-bold hidden md:block">Request an Authorization</span>
          <span className="text-black text-xl font-bold block md:hidden pl-3">Request an Auth</span>
          <Button
            variant="primary"
            className="!rounded-full !whitespace-nowrap ml-auto"
            onClick={openCreateModal}
          >
            <PlusIcon className="[&_path]:stroke-white" />
            Request Auth
          </Button>
        </div>
      </div>
      <div className="mt-5">
        <ExpandableRequestsList requests={authRequests.filter(r => r.isActive)} title="Active" defaultOpen={true} />
        <ExpandableRequestsList requests={authRequests.filter(r => !r.isActive)} title="Closed" defaultOpen={false} />
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {showCreateModal && (
          <AuthRequestModal onClose={async () => {
            await refreshRequests();
            setShowCreateModal(false);
          }} />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {authRequestToEdit && (
          <AuthRequestModal
            authRequestId={authRequestToEdit?.id}
            onClose={async () => {
              await refreshRequests();
              setAuthRequestToEdit(undefined);
            }} />
        )}
      </AnimatePresence>
    </div>
  )
};
