import { Button } from "../../../../components/Button";
import { PopupModal } from "../../../../components/PopupModal";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { useState } from "react";
import { SelectInput } from "../../../../components/SelectInput";
import { LeaveProviderReason, prettyLeaveReason } from "./ProviderLeave.types";
import { TextInput } from "../../../../components/TextInput";
import { TextArea } from "../../../../components/Textarea";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../services/api";
import { getUser } from "../../../../services/utils";

export function DifferentProviderConfirm({
  oldProviderName,
  oldProviderId,
  newProviderId,
  onRefresh,
  onClose,
}: {
  oldProviderId: string;
  oldProviderName: string;
  newProviderId?: string;
  onRefresh: () => void;
  onClose: () => void;
}) {
  const navigate = useNavigate();
  const [confirmStep, setConfirmStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<{ type?: LeaveProviderReason; comments?: string }>({});
  const user = getUser();

  const onMakeSwitch = async () => {
    setLoading(true);
    await api.switchAwayFromProvider(oldProviderId, form.type!, form.comments);
    setLoading(false);
    if (newProviderId && user?.id) {
      try {
        const response = await api.scheduleAppointment({
          providerId: newProviderId,
          patientId: user.id,
        });
        onRefresh();
        onClose();

      } catch (error: any) {
        onRefresh();
        onClose();
      }
    } else {
      setConfirmStep(3);
    }
  }

  if (confirmStep === 1) {
    return <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="w-full justify-between items-start flex">
        <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
          Switch Care Providers?
        </div>
        <div className="justify-end items-center gap-3 flex">
          <button onClick={onClose}>
            <CloseIcon className="w-6 h-6" />
          </button>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <p className="text-left text-l text-black/80 font-bold font-medium">
          This will terminate the chat with your existing care provider, {oldProviderName}
        </p>
        <div className="flex gap-3 items-center w-full mt-8">

          <Button
            onClick={onClose}
            variant="outline"
            className="!rounded-full w-full flex-1"
            disabled={loading}
            loading={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setConfirmStep(2)
            }}
            className="!rounded-full w-full flex-1"
            variant="primary"
            disabled={loading}
            loading={loading}
          >
            Switch Providers
          </Button>

        </div>
      </div>
    </PopupModal>
  }

  if (confirmStep === 2) {
    return <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="w-full justify-between items-start flex">
        <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
          Switch Care Providers?
        </div>
        <div className="justify-end items-center gap-3 flex">
          <button onClick={onClose}>
            <CloseIcon className="w-6 h-6" />
          </button>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <p className="text-left text-l text-black/80 font-bold font-medium">
          Why are you looking to talk to a different provider?
        </p>

        <SelectInput
          data={
            Object.values(LeaveProviderReason).map((type) => ({
              value: type, label:
                prettyLeaveReason(type)
            }))
          }
          name="type"
          value={form.type}
          disabledOptionText={"Select Reason"}
          onChange={(e) => {
            setForm({ ...form, type: e.target.value as LeaveProviderReason });
          }}
          className={`h-10 py-0 mt-4`}
        />

        {form.type && <>
          <span className="text-black/80 mt-6 text-sm">Comments (Optional)</span>
          <TextArea
            className={`p-[10px]`}
            name="comments"
            rows={3}
            value={form.comments}
            placeholder=""
            onChange={(e) => {
              setForm({ ...form, comments: e.target.value })
            }}
          />
        </>}

        <div className="flex gap-3 items-center w-full mt-8">
          <Button
            onClick={onMakeSwitch}
            variant="primary"
            className="!rounded-full w-full flex-1"
            disabled={loading || !form.type}
            loading={loading}
          >
            Switch Providers
          </Button>

        </div>
      </div>
    </PopupModal>
  }

  if (confirmStep === 3) {
    return <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="w-full justify-between items-start flex">
        <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
          Switch Providers
        </div>
      </div>

      <div className="flex flex-col w-full">
        <p className="text-left text-l text-black/80 font-bold font-medium">
          We're sorry it didn't work out with {oldProviderName}. Please browse providers to select a new one.
        </p>

        <div className="flex gap-3 items-center w-full mt-8">
          <Button
            onClick={() => navigate("/providers")}
            variant="primary"
            className="!rounded-full w-full flex-1"
          >
            Browse Providers
          </Button>

        </div>
      </div>
    </PopupModal>
  }

  return <></>;
}
