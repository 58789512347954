import { useEffect, useState } from "react";
import { useRbtRequests } from "./context/rbt-request-context";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down-green.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { Button } from "../../../components/Button/Button";
import moment from "moment";
import { AnimatePresence } from "framer-motion";
import { RbtRequestModal } from "./modals/RbtRequestModal";
import { RbtRequest, rbtRequestStatusColor } from "./RequestRbt.types";
import { RbtCandidateStatus } from "./RbtCandidate.types";

export function RequestRBT() {
  const { rbtRequests, refreshRequests } = useRbtRequests();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [rbtRequestToEdit, setRbtRequestToEdit] = useState<RbtRequest | undefined>();

  useEffect(() => {
    refreshRequests()
  }, [])

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const ExpandableRequestsList = ({ requests, title, defaultOpen }: { requests: RbtRequest[], title: string, defaultOpen: boolean }) => {
    const [expanded, setExpanded] = useState(defaultOpen);

    const fullTitle = `${title} Request${requests.length > 1 ? 's' : ''} (${requests.length})`
    const chevron = (expanded) ? <ChevronRight className="inline-block mr-1" style={{ width: "25px", height: "25px", marginBottom: "3px" }} /> :
      <ChevronDown className="inline-block mr-1" style={{ width: "25px", height: "25px", marginBottom: "3px" }} />

    const noRequestsMessage = <div className="pb-10">
      <div className="text-center mt-3 pb-3">You have no {title.toLowerCase()} requests</div>
      {defaultOpen && <Button
        variant="primary"
        className="!rounded-full !whitespace-nowrap mx-auto"
        onClick={openCreateModal}
      >
        <PlusIcon className="[&_path]:stroke-white" />
        Request RBT
      </Button>}
    </div>

    const orderedRequests = requests.sort((a, b) => (b.createdAt ? new Date(b.createdAt).getTime() : new Date().getTime()) - (a.createdAt ? new Date(a.createdAt).getTime() : new Date().getTime()));

    return <div className="w-full bg-white my-10 rounded-lg" >
      <div className="inline-block text-sm md:text-base lg:text-lg cursor-pointer w-full ml-3 my-2" onClick={() => setExpanded(!expanded)}>
        {chevron}
        {fullTitle}
      </div>
      {expanded && <>
        {orderedRequests.length === 0 ? noRequestsMessage : <>
          <table className="w-full text-xs md:text-sm">
            <colgroup>
              <col style={{ width: "25%" }} />
              <col style={{ width: "18%" }} />
              <col style={{ width: "18%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "19%" }} />
            </colgroup>
            <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
              <tr className="sticky top-0 z-[1]">
                <td className="rounded-l pl-5">
                  <div className="items-center">
                    <span className="hidden md:block">Request #</span>
                    <span className="block md:hidden">#</span>

                  </div>
                </td>
                <td>
                  <div className="items-center text-center">
                    <span className="hidden md:block">Submitted Date</span>
                    <span className="block md:hidden">Submitted</span>

                  </div>
                </td>
                <td>
                  <div className="items-center text-center">
                    <span className="hidden md:block">Desired Start Date</span>
                    <span className="block md:hidden">Start</span>

                  </div>
                </td>
                <td>
                  <div className="items-center text-center">
                    Status
                  </div>
                </td>
                <td>
                  <div className="items-center text-center">
                    <span className="hidden md:block">Candidate for Review?</span>
                    <span className="block md:hidden">Candidate?</span>
                  </div>
                </td>
                <td className="rounded-r"></td>
              </tr>
            </thead>
            <tbody>
              {orderedRequests.map(request => <>
                <tr
                  key={request.friendlyId}
                  className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                  onClick={() => setRbtRequestToEdit(request)}
                >
                  <td className="name pl-5">
                    <div className="inline-block">
                      {request?.rbtCandidates?.filter(c => c.status && [RbtCandidateStatus.PROVIDER_REVIEW, RbtCandidateStatus.NEGOTIATING].includes(c.status)).length > 0 ?
                        <h1 className='before:inline-block before:w-[5px] before:h-[5px] before:mb-[3px] before:ml-[3px] before:mr-[3px]
                          before:bg-primary before:rounded-full '>{request.friendlyId}</h1>
                        :
                        <h1>{request.friendlyId}</h1>
                      }
                    </div>
                  </td>

                  <td className="mrr text-center">
                    <span className="hidden md:block">{moment.utc(request.createdAt).local().format("MMM DD,YYYY")}</span>
                    <span className="block md:hidden">{moment.utc(request.createdAt).local().format("MM/DD")}</span>
                  </td>
                  <td className="mrr text-center">
                    <span className="hidden md:block">{moment.utc(request.desiredStart).local().format("MMM DD,YYYY")}</span>
                    <span className="block md:hidden">{moment.utc(request.desiredStart).local().format("MM/DD")}</span>
                  </td>
                  <td className="name">
                    <div className="text-center">
                      <h5 className="" style={request.status && { color: rbtRequestStatusColor(request.status) }}>
                        {request.status}
                      </h5>
                    </div>
                  </td>
                  <td className="name">
                    <div className="">
                      <h5 className="text-center">
                        {request?.rbtCandidates?.filter(c => c.status && [RbtCandidateStatus.PROVIDER_REVIEW, RbtCandidateStatus.NEGOTIATING].includes(c.status)).length > 0 ?
                          <div className="font-bold text-primary">
                            {request?.rbtCandidates[0].reviewDeadline ?
                              <>
                                <Check className="inline-block mr-1" style={{ width: "25px", height: "25px", marginBottom: "3px" }} />
                                By {moment.utc(request?.rbtCandidates[0].reviewDeadline).local().format("MMM DD,YYYY")}
                              </>
                              :
                              <>
                                <Check className="mr-1 hidden sm:inline-block" style={{ width: "25px", height: "25px", marginBottom: "3px", marginLeft: "-21px" }} />
                                <span className="text-primary">YES</span>
                              </>
                            }
                          </div>
                          : 'No'}
                      </h5>
                    </div>
                  </td>
                </tr >
              </>)}
            </tbody>
          </table>
        </>}
      </>
      }
    </div >
  }


  return (
    <div>
      <div className="mb-7 flex flex-col gap-5">
        <div className="flex justify-between items-center gap-5">
          <span className="text-black text-xl lg:text-3xl font-bold">Request RBT</span>
          <Button
            variant="primary"
            className="!rounded-full !whitespace-nowrap ml-auto"
            onClick={openCreateModal}
          >
            <PlusIcon className="[&_path]:stroke-white" />
            Request RBT
          </Button>
        </div>
      </div>
      <div className="mt-5">
        <ExpandableRequestsList requests={rbtRequests.filter(r => r.isActive)} title="Active" defaultOpen={true} />
        <ExpandableRequestsList requests={rbtRequests.filter(r => !r.isActive)} title="Closed" defaultOpen={false} />
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {showCreateModal && (
          <RbtRequestModal onClose={async () => {
            await refreshRequests();
            setShowCreateModal(false);
          }} />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {rbtRequestToEdit && (
          <RbtRequestModal
            rbtRequestId={rbtRequestToEdit?.id}
            onClose={async () => {
              await refreshRequests();
              setRbtRequestToEdit(undefined);
            }} />
        )}
      </AnimatePresence>
    </div>
  )
};
