import { useEffect, useRef, useState } from "react";
import { Button } from "../../../components/Button";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as PinIcon } from "../../../assets/icons/pin.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";
import { fileUpload } from "../../../services/file-upload";
import { getToken, getUser, setUser } from "../../../services/utils";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import { FileUploader } from "react-drag-drop-files";
import { ReferralFormType } from "./ClientReferral";
import { TextArea } from "../../../components/Textarea";

export function Step3({
  onSubmit,
  referralForm,
  setReferralForm
}: {
  onSubmit: (form: ReferralFormType) => void;
  referralForm: ReferralFormType;
  setReferralForm(value: ReferralFormType): void;
}) {
  const [saving, setSaving] = useState(false);
  const [cdeFile, setCdeFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<any>({});
  const [cdeExists, setCdeExists] = useState<Boolean | undefined>(undefined);

  const handleFileChange = (file) => {
    setSaving(false);
    setCdeFile(file);
  };
  const handleSubmit = async () => {
    if (cdeExists === undefined) {
      return;
    }

    try {
      if (cdeExists && cdeFile) {
        // upload the files
        setSaving(true);
        const uploadResponse = await fileUpload(
          cdeFile,
          crypto.randomUUID(),
          "CDE"
        );
        setSaving(false);
        // ShowToast({
        //   message: "DE Uploaded!",
        //   type: "success",
        // });
        onSubmit({ ...referralForm, cdeUrl: uploadResponse.url });
      }
    } catch (error) {
      setSaving(false);
      ShowToast({
        message: "Something went wrong while uploading the files",
        type: "error",
      });
    }

    if (!cdeExists) {
      onSubmit(referralForm);
    }

  };

  return (
    <>
      <div className="bg-white p-8 rounded-[16px]">
        <div>
          <h1 className="text-3xl font-bold mb-5">Client Referral - ABA Therapy</h1>
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Diagnostic Information</h2>
            <div className="text-sm text-black/60">2/3</div>
          </div>
        </div>
        <p className="mt-4 font-bold text-xs md:text-sm lg:text-base">
          Are you able to provide a copy of the Diagnostic Evaluation (DE) for the individual looking to receive services?
        </p>

        <div className="grid grid-cols-2 gap-3 items-center w-full mt-8">
          <Button
            variant={(cdeExists === true || cdeExists === undefined) ? "primary" : "gray"}
            className="px-4 py-2 !rounded-full justify-center items-center gap-2.5 flex"
            disabled={saving}
            onClick={() => {
              if (cdeExists !== true) {
                setCdeExists(true)
              } else {
                if (cdeExists === true) {
                  setCdeExists(undefined);
                }
              }
            }}>
            <CheckIcon className="[&_path]:stroke-white" />
            Yes
          </Button>
          <Button
            variant={(cdeExists === false || cdeExists === undefined) ? "primary" : "gray"}
            className="px-4 py-2 !rounded-full justify-center items-center gap-2.5 flex"
            disabled={saving}
            onClick={() => {
              if (cdeExists === false) {
                setCdeExists(undefined);
              } else {
                setCdeExists(false)
              }

            }}
          >
            <XIcon className="[&_path]:stroke-white" />
            No
          </Button>
        </div>

        {cdeExists === true && <>
          <div className="text-black/80 text-sm mt-10 mb-1">Please upload a copy of the DE</div>
          <FileUploader handleChange={handleFileChange} name="file" disabled={saving}>
            <div className="cursor-pointer p-4 h-[114px] bg-[#E8F3F2] w-full rounded-xl border border-primary justify-center items-center gap-1 flex">
              <PinIcon className="w-5 h-5" />
              <div className="text-primary text-base font-normal font-['Outfit'] leading-normal">
                {cdeFile
                  ? `File "${cdeFile?.name}" added`
                  : "Click to add or drag and drop"}
              </div>
            </div>
          </FileUploader>
          {errors.cdeFile && (
            <div className="text-red-500 text-xs font-medium">
              {errors.cdeFile}
            </div>
          )}

        </>}

        <p className="mt-12"></p>

        <div className="flex flex-col mb-2">
          <span className="text-black/80 text-sm">Additional Information (Optional)</span>
          <TextArea
            placeholder="Any additional information about the client referral"
            className={`!p-[14px]`}
            name="comments"
            rows={3}
            value={referralForm.comments}
            onChange={(e) => {
              setReferralForm({ ...referralForm, comments: e.target.value });
            }}
            disabled={saving}
          />
          {errors.comments && (
            <div className="text-red-500 text-xs font-medium">
              {errors.comments}
            </div>
          )}
        </div>

        <div className="mt-10">
          <Button
            type="submit"
            variant="primary"
            className="!rounded-full w-full"
            onClick={handleSubmit}
            disabled={
              saving || cdeExists === undefined || (cdeExists === true && !cdeFile)
            }
            loading={saving}
          >
            Submit Referral
          </Button>
        </div>
      </div>
    </>
  );
}
