import { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { ProgressStep } from "./ProgressStep";
import { FamilyRegistrationStep1 } from "./Step1";
import { FamilyRegistrationStep2 } from "./Step2";
import { FamilyRegistrationStep3 } from "./Step3";
import { useApp } from "../../../services/app-context";
import { useNavigate, useParams } from "react-router-dom";
import { ProviderDetails } from "../../providers/ProviderDetails";
import { getUser } from "../../../services/utils";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import { SelectProviderPopup } from "./SelectProviderPopup";
import { PartialSignUp } from "../../providers/partial-signup-patient.types";
import { useProvider } from "../../providers/context/provider-context";
import { ScheduleConsultModal } from "../../providers/ScheduleConsultModal";


const MAX_REGISTRATION_STEP = 3;

export function FamilyRegistration({ defaultStep }: { defaultStep?: number }) {
  const {
    providers
  } = useProvider() as any;
  const navigate = useNavigate();
  const params = useParams();
  const [step, setStep] = useState(2);
  const [registrationStep, setRegistrationStep] = useState(1);
  const [isGoogleId, setIsGoogleId] = useState(false);
  const { selectedProvider, setSelectedProvider } = useApp() as any;
  const user = getUser();
  const [partialSignUpInfo, setPartialSignUpInfo] = useState<PartialSignUp | undefined>(undefined);

  const handleNext = () => {
    if (registrationStep === MAX_REGISTRATION_STEP) {
      // check for the last step
      // if selecredProvider is there then go to the next step
      // else redirect to the provider page
      if (selectedProvider) {
        // setStep(3);
        handleSchedule(selectedProvider)
      } else {
        setStep(4);
      }
      return;
    }
    setRegistrationStep((prev) => prev + 1);
  };

  const handleClose = () => {
    setSelectedProvider(null);
    navigate("/providers");
  };

  const handleSchedule = async (provider) => {
    try {
      // check if user is logged in
      if (!user?.id) {
        // redirect to register page
        navigate("/register");
      } else {
        // redirect to messaging page
        // create schedule appointment
        const payload = {
          providerId: provider.id,
          patientId: user?.id,
        };
        const response = await api.scheduleAppointment(payload);
        if ([200, 201].includes(response.status)) {
          ShowToast({
            message: "You've successfully created a Headstart account",
            type: "success",
          });
          navigate("/family/dashboard");
        } else {
          ShowToast({
            message: response?.data?.message || "Something went wrong",
            type: "error",
          });
        }
      }
    } catch (error: any) {
      ShowToast({
        message:
          "Something went wrong while scheduling the appointment, please try again later.",
        type: "error",
      });
    }
  };


  useEffect(() => {
    const partialId = params?.partialId
    if (partialId) {
      try {
        api.getPartialClientSignup(partialId).then((response) => {
          if (response.status !== 200) {
            navigate("/register")
          } else {
            setPartialSignUpInfo(response.data)
          }
        }).catch(error => {
          navigate("/register")
        });
      } catch (error) {
        navigate("/register")
      }
    } else {
      navigate("/login?signup=true");
    }
  }, [params?.partialId])

  useEffect(() => {
    if (!providers || !partialSignUpInfo) return;
    setTimeout(() => {
      const desiredProvider = providers.filter(p => p?.id === partialSignUpInfo.providerId)[0];
      if (desiredProvider) {
        setSelectedProvider(desiredProvider)
      }
    }, 1000)
  }, [providers, partialSignUpInfo, setSelectedProvider])

  useEffect(() => {
    if (defaultStep) {
      setRegistrationStep(defaultStep);
    }
  }, [defaultStep]);

  return (
    <div
      className="h-screen bg-secondary overflow-auto"
      style={{ backgroundImage: "url('/images/authpage-overlay.png')" }}
    >
      <Header showSignIn />
      <div className="py-10 px-4 md:px-0">
        <div className="flex justify-center items-center">
          <div className="max-w-[820px] lg:w-[63vw]">
            {isGoogleId && (
              <div className="text-center mb-[30px]">
                <span className="text-[28px] font-bold text-black">
                  Complete your profile
                </span>
              </div>
            )}
            <ProgressStep
              currentStep={registrationStep}
              isGoogleId={isGoogleId}
            />
            {step === 2 && registrationStep === 1 && (
              <FamilyRegistrationStep1
                onNext={handleNext}
                onGoogleId={(id) => setIsGoogleId(!!id)}
                isFromLogin={!!defaultStep}
                partialSignUpInfo={partialSignUpInfo}
              />
            )}
            {step === 2 && registrationStep === 2 && (
              <FamilyRegistrationStep2
                onNext={handleNext}
                isFromLogin={!!defaultStep}
              />
            )}
            {step === 2 && registrationStep === 3 && (
              <FamilyRegistrationStep3
                onNext={handleNext}
                isFromLogin={!!defaultStep}
              />
            )}
          </div>
        </div>
      </div>
      {step === 3 && (
        <ProviderDetails
          provider={selectedProvider}
          isOpen={step === 3}
          onClose={handleClose}
          onSchedule={() => handleSchedule(selectedProvider)}
        />
      )}
      {step === 4 && (
        <SelectProviderPopup
          onSchedule={(provider) => {
            handleSchedule(provider)
            setSelectedProvider(provider)
          }}
          onBrowserProviders={() => navigate("/providers")}
        />
      )}
    </div>
  );
}
